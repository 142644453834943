<script>
	export let as = 'div';

	let className = '';
	export { className as class };
</script>

<svelte:element this={as} class="container {className}"><slot /></svelte:element>

<style>
	.container {
		margin-left: auto;
		margin-right: auto;
		padding-left: 16px;
		padding-right: 16px;
		width: 100%;
	}

	@media (min-width: 740px) {
		.container {
			max-width: 740px;
			padding-left: 32px;
			padding-right: 32px;
		}
	}

	@media (min-width: 1092px) {
		.container {
			max-width: calc(1024px + 64px + 300px);
		}
	}
</style>
